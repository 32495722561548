import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeShuffle,
    placeTest,
    placeTape
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 17, 15, -3],
    keepaspectratio: true, axis:true, ticks:{visible:true},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();

brd1.options.layer['image'] =14;
brd1.options.layer['polygon'] =1;
//Title and subtitle
placeTitle(brd1, 'Building an Expression', '(Height of a stack of bottles)');
//Variables
placeLogo(brd1);
var a = 1;
var b = 2;
var c = 3;
var d = 4;
var o = 2;
var j =1;
//brd1.create('image', ['/assets/oneR.svg', [9, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/twoR.svg', [11.5, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/threeR.svg', [14, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//var polynomial1 = brd1.create('text', [9.2, 16, function(){return  a + ' + &nbsp;'}],{color:'red', fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}});
//var polynomial2 = brd1.create('text', [10.5, 16, function(){return  b}],{color:'blue',fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}});
//var polynomial3 = brd1.create('text', [11.3, 16, function(){return ' x'}],{color:'green',fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}});
//var polynomial4 = brd1.create('text', [12.5, 16, function(){return ' '+ d }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var flr = placeRec(brd1, [-5, -5], [20, -5], [20, 0], [-5, 0], 'white');
placeText(brd1, 4.5, 8, 32, 'h =')
var inp = placeInput(brd1, 5, 8, "");
placeText(brd1, 7.5, 8, 32, 'n')
var rght = placeImage(brd1,'/assets/check.svg', 9, 7, 2, 0);
rght.setAttribute({visible:false});
var wrng = placeImage(brd1,'/assets/cross.svg', 9, 7, 2, 0);
wrng.setAttribute({visible:false});
//flr.setAttribute({fillOpacity:0.75});
var dollarPlus = placeImage(brd1, '/assets/plus.svg', -4, -2, 1, 0);
var dollarMinus = placeImage(brd1, '/assets/minuss.svg', -2.5, -2, 1., 0);
///////////////////////////////////////////////////
var i=3;
dollarPlus.on('down',function(){
      i=i+1;
  });
dollarMinus.on('down',function(){
      i=Math.max(i-1, 0);
    });
////////////////////////////////////////////////
placeMiddleText(brd1, ()=>-4+0.5*m, ()=>m*i+0.5, ()=>'n =' + i);
var m =2;
var bots=[];
var pos=[];
var name=['cola', 'coffee', 'bottle'];
for(let n=0;n<=20;n++)
{ pos[n]=n;
  bots[n]=placeImage(brd1, ()=>'/assets/'+name[m-2]+'.svg', -4, ()=>m*n, ()=>m, 0);
  bots[n].setAttribute({visible:false});
  bots[n].setAttribute({visible:()=>i>pos[n]});
}
/*bots[0].setAttribute({visible:()=>i>0});
bots[1].setAttribute({visible:()=>i>1});
bots[2].setAttribute({visible:()=>i>2});
bots[3].setAttribute({visible:()=>i>3});
bots[4].setAttribute({visible:()=>i>4});
bots[5].setAttribute({visible:()=>i>5});
bots[6].setAttribute({visible:()=>i>6});
bots[7].setAttribute({visible:()=>i>7});
*/
////////////////////////////////////////////////////
placeTape(brd1, [0,0], [0, ()=>m*i], 'h');
//////////////////////////////////////////////////
var dash1 = placeLine(brd1, [10.5, 13], [10.5, 15.4], 2, 'blue');
var dash2 = placeLine(brd1, [11.3, 11], [11.3, 15.4], 2, 'green');
var dash3 = placeLine(brd1, [12.5, 9], [12.5, 15.4], 2, 'green');
var dash0 = placeLine(brd1, [9.5, 16.6], [9.5, 19.], 2, 'red');
dash0.setAttribute({visible:false});
dash1.setAttribute({visible:false});
dash2.setAttribute({visible:false});
dash3.setAttribute({visible:false});
var txt0 = brd1.create('text', [9.3, 19., 'Constant'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'red', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt1 = brd1.create('text', [10.3, 13, 'Coefficient'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'blue', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt2 = brd1.create('text', [11.1, 11, 'Variable'],{visible:false,display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'green', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt3 = brd1.create('text', [12.3, 9, '1000th Place'],{visible:false,display:'internal',highlight:false, fixed: true, anchorX: 'right', anchorY: 'middle', color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);

var analytics =placeShuffle(brd1);
analytics.setLabel('Tap to Shuffle')
analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
analytics.on('over', function () {this.label.setAttribute({visible:true});});
analytics.on('out', function () {this.label.setAttribute({visible:false});});

var erase =placeTest(brd1);
erase.setLabel('Tap to Test Your Answer')
erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
erase.on('over', function () {this.label.setAttribute({visible:true});});
erase.on('out', function () {this.label.setAttribute({visible:false});});
//Erase Function
erase.on('down',function(){
  if(inp.Value() == m){
    rght.setAttribute({visible:true});
    wrng.setAttribute({visible:false});
  }
  else{
    rght.setAttribute({visible:false});
    wrng.setAttribute({visible:true});
  }
});
//Shuffle function
analytics.on('down',function(){
    i=3;
    if(m<4){m = m+1}else{m=2};
    //coefficient1.Value() = 'ppp'; // I want to reset the input values
    rght.setAttribute({visible:false});
    wrng.setAttribute({visible:false});
});
brd1.unsuspendUpdate();
}
}
export default Boxes;
